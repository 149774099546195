<template>
  <section class="hero-area overlay" id="home">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-12 col-md-12 col-12">
          <div class="hero-content">
            <h1>personalized<br />cloud solutions<br />&<br />IT services</h1>
            <a href="#about" class="about-btn"
              ><i class="lni lni-chevron-down"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style>
.hero-area:before {
  content: "";
  background: rgba(6, 12, 34, 0.5);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.hero-area {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 160px 0;
  background-image: url("../../assets/images/Banner.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 2561px) and (min-width: 993px) {
  .hero-area .hero-content {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 992px) and (min-width: 250px) {
  .hero-area .hero-content {
    margin-top: -60px !important;
  }
  .hero-area {
    height: 80vh !important;
  }
  .about-btn {
    font-size: 10px !important;
    padding: 10px !important;
    margin: 20px 0px 0px 0px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1299px) {
  .hero-area .hero-content h1 {
    font-size: 50px;
    line-height: 70px;
  }
  .hero-area {
    padding: 150px 0 !important;
  }
}

@media only screen and (min-width: 901px) and (max-width: 991px) {
  .hero-area .hero-content {
    text-align: center;
  }
  .hero-area .hero-content h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 70px;
  }
  .hero-area {
    padding: 120px 0;
    height: 100vh !important;
  }
}
@media only screen and (min-width: 831px) and (max-width: 900px) {
  .hero-area .hero-content h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
  }
  .hero-area {
    padding: 160px 0;
    height: 60vh !important;
  }
}
@media only screen and (min-width: 801px) and (max-width: 830px) {
  .hero-area .hero-content h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
  }
  .hero-area {
    padding: 160px 0;
    height: 40vh !important;
  }
}
@media only screen and (min-width: 701px) and (max-width: 800px) {
  .hero-area .hero-content h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 65px;
  }
  .hero-area {
    padding: 120px 0;
    height: 50vh !important;
  }
}
@media only screen and (min-width: 1501px) and (max-width: 2561px) {
  .hero-content h1 {
    font-size: 100px !important;
    line-height: 130px !important;
  }
  .hero-area {
    padding: 260px 0 !important;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .hero-content h1 {
    font-size: 75px !important;
    line-height: 110px !important;
  }
  .hero-area {
    padding: 180px 0 !important;
  }
}
@media only screen and (min-width: 601px) and (max-width: 700px) {
  .hero-area .hero-content h1 {
    font-size: 35px;
    font-weight: 700;
    line-height: 60px;
  }
  .hero-area {
    padding: 120px 0;
    height: 100vh !important;
  }
}
@media only screen and (min-width: 401px) and (max-width: 600px) {
  .hero-area .hero-content h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 60px;
  }
  .hero-area {
    padding: 120px 0;
    height: 100vh !important;
  }
}
@media only screen and (min-width: 0px) and (max-width: 400px) {
  .hero-content h1 {
    font-size: 30px !important;
    line-height: 50px !important;
  }
}
@media (max-width: 767px) {
  .hero-area .hero-content {
    padding: 0 10px;
  }
  .hero-area .hero-content {
    margin: 0;
  }
}

.hero-area .hero-content {
  border-radius: 0;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-align: center;
}
.hero-content h1 {
  color: #fff;
  font-size: 60px;
  line-height: 80px;
  font-weight: 600;
  text-transform: uppercase;
}
.hero-content h1 span {
  color: #fff;
}

.about-btn {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 40px 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #fff;
}

.about-btn:hover {
  background: #000000;
  color: #fff;
}
</style>
