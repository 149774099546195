<template>
  <section id="about" class="about">
    <div class="container">
      <div class="row">
        <div class="col-md-7 aboutsection">
          <div class="section-title">
            <h2>About Us</h2>
            <p style="margin-bottom: 40px">
              Vezham Technologies Private Limited is headquartered in Theni,
              Tamil Nadu, India with a strong presence in IT Services and IT
              Consulting. Our company is built with individuals who are
              passionate, highly qualified, and experienced professionals who
              believe in developing true partnerships. We provide exceptional
              services with a passion that creates tremendous value for our
              customers.
            </p>
            <h2>Our Vision</h2>
            <p>
              Our Company was founded with a vision to provide personalized
              value-added solutions to everyone in the virtual space. We believe
              in earning the trust of our clients by providing maximum benefits
              from their investments. We undertake challenging projects & lift
              them with innovative methodologies.
            </p>
          </div>
        </div>
        <div class="col-md-5 aboutsection">
          <div class="block">
            <img
              src="@/assets/images/AboutUs.jpg"
              alt="Img"
              style="width: 100%"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style>
/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about {
  padding: 130px 30px;
  background: #f9f9f9;
  position: relative;
}
.aboutsection {
  padding: 30px;
}
.about:before {
  content: "";
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.about .container {
  position: relative;
}

.about .content h2 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 26px;
}

@media (max-width: 992px) {
  .about {
    padding: 30px;
  }
}
</style>
