<template>
	<nav class="navbar navbar-expand-lg navbar-dark primary">
		<div class="container-fluid">
			<a class="navbar-brand" href="../">
				<ICON_Logo :size="70" color="black"
			/></a>
			<button
				class="navbar-toggler"
				type="button"
				data-bs-toggle="collapse"
				data-bs-target="#main_nav"
				id="burger"
				:class="{active: isBurgerActive}"
				@click.prevent="toggle">
				<button type="button" class="burger-button" title="Menu">
					<span class="burger-bar burger-bar--1"></span>
					<span class="burger-bar burger-bar--2"></span>
					<span class="burger-bar burger-bar--3"></span>
				</button>
				<!-- <span class="navbar-toggler-icon"></span> -->
			</button>
			<div class="collapse navbar-collapse" id="main_nav">
				<ul class="navbar-nav mx-auto">
					<li class="nav-item">
						<a class="nav-link" href="../#home">HOME</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="../#about">ABOUT US</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="">
							PRODUCTS<sup class="comingsoon">Coming Soon</sup></a
						>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="../#services">SERVICES</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="../#contact">CONTACT US</a>
					</li>
				</ul>
			</div>
			<!-- navbar-collapse.// -->
		</div>
		<!-- container-fluid.// -->
	</nav>
</template>
<script>
import ICON_Logo from "@/assets/icons/Logo.vue";

export default {
	components: {
		ICON_Logo,
	},
	data: () => ({
		isBurgerActive: false,
	}),
	methods: {
		toggle() {
			this.isBurgerActive = !this.isBurgerActive;
			var navMain = document.getElementById("main_nav");
			var buttonmenu = document.getElementById("burger");
			navMain.addEventListener("click", function () {
				navMain.classList.remove("show"), buttonmenu.classList.remove("active");
			});
		},
	},
};
</script>
<style scoped>
.navbar-brand {
	padding: 0px 80px;
}
.hidden {
	visibility: hidden;
}

button {
	cursor: pointer;
}

sup.comingsoon {
	background: #000000;
	color: #fff;
	padding: 3px 5px;
	font-weight: 500;
	border-radius: 100px;
	font-size: 12px;
}

/* remove blue outline */
button:focus {
	outline: 0;
}

.burger-button {
	position: relative;
	height: 30px;
	width: 32px;
	display: block;
	z-index: 999;
	border: 0;
	border-radius: 0;
	background-color: transparent;
	pointer-events: all;
	transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar {
	background-color: #000000;
	position: absolute;
	top: 50%;
	right: 6px;
	left: 6px;
	height: 2px;
	width: auto;
	margin-top: -1px;
	transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
		opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
		background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar--1 {
	-webkit-transform: translateY(-6px);
	transform: translateY(-6px);
}

.burger-bar--2 {
	transform-origin: 100% 50%;
	transform: scaleX(0.8);
}

.burger-button:hover .burger-bar--2 {
	transform: scaleX(1);
}

.no-touchevents .burger-bar--2:hover {
	transform: scaleX(1);
}

.burger-bar--3 {
	transform: translateY(6px);
}

#burger.active .burger-button {
	transform: rotate(-180deg);
}

#burger.active .burger-bar {
	background-color: #000000;
}

#burger.active .burger-bar--1 {
	transform: rotate(45deg);
}

#burger.active .burger-bar--2 {
	opacity: 0;
}

#burger.active .burger-bar--3 {
	transform: rotate(-45deg);
}

a.nav-link > a:hover {
	color: #fff;
}
.navbar .navbar-nav .nav-link {
	color: #000000;
	font-size: 16px;
	font-weight: 500;
}

.navbar-dark .navbar-nav .nav-link:focus {
	color: #000000 !important;
}
@media (min-width: 992px) {
	.navbar .megamenu {
		left: 10px !important;
		width: 98% !important;
	}
}
.navbar .navbar-nav .nav-link:hover {
	color: #000000;
}
.sm-icons {
	flex-direction: row;
}
@media only screen and (max-width: 960px) {
	.sm-icons .nav-item {
		padding-right: 1em;
	}
}
.navbar .megamenu {
	padding: 1rem;
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {
	.navbar .has-megamenu {
		position: static !important;
	}
	.navbar .megamenu {
		left: 0;
		right: 0;
		width: 100%;
		margin-top: 0;
	}
}
.navbar-collapse {
	flex-grow: 0;
	padding: 0px 60px;
}
/* ============ desktop view .end// ============ */

/* ============ mobile view ============ */
@media (max-width: 451px) and (min-width: 0px) {
	div#main_nav {
		width: 95% !important;
	}
	img.logo {
		width: 70px !important;
		margin: 0px 10px !important;
	}
}

@media all and (max-width: 991px) {
	.navbar.fixed-top .navbar-collapse,
	.navbar.sticky-top .navbar-collapse {
		overflow-y: auto;
		max-height: 90vh;
		margin-top: 10px;
	}
	.navbar-brand {
		padding: 0px 30px !important;
	}
	nav.navbar {
		bottom: 0;
	}
	div#main_nav {
		bottom: 80px !important;
	}

	div#main_nav {
		position: absolute;
		left: 0;
		width: 97%;
		margin: 10px;
		background-color: #f6f7fd;
		z-index: 99;
		-webkit-box-shadow: 0px 15px 20px 0px rgb(0 0 0 / 10%);
		box-shadow: 0px 15px 20px 0px rgb(0 0 0 / 10%);
		padding: 10px 20px;
		max-height: 550px;
		overflow-y: scroll;
		border-top: 1px solid #eee;
		border-radius: 6px;
	}
}
img.logo {
	width: 70px;
	margin: 0px 50px;
}

nav.navbar.navbar-expand-lg.navbar-dark.primary {
	position: fixed;
	z-index: 99;
	-webkit-box-shadow: 0px 20px 50px 0px rgb(0 0 0 / 5%);
	box-shadow: 0px 20px 50px 0px rgb(0 0 0 / 5%);
	background: #f6f7fd;
	padding: 10px 0;
	width: 100%;
	margin: 40px 0px 0px 0px;
}
li.nav-item > a {
	padding: 10px;
	text-decoration: none;
}
li.nav-item {
	padding: 10px 15px;
}
.submenu {
	float: left;
	background: #fff;
	-webkit-box-shadow: 0px 5px 20px #0000001a;
	box-shadow: 0px 5px 20px #0000001a;
}
</style>
