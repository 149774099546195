<template>
  <section id="why-us" class="why-us">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-5 align-items-stretch video-box"></div>
        <div
          class="col-lg-7 datasection d-flex flex-column justify-content-center align-items-stretch"
          data-aos="fade-left"
        >
          <div class="content">
            <h3 style="margin-bottom: 30px">Why Choose Us?</h3>
          </div>

          <div class="accordion-list">
            <ul>
              <li>
                <a
                  data-bs-toggle="collapse"
                  class="collapse"
                  data-bs-target="#accordion-list-1"
                  ><span>01</span> TRANSPARENT PRICING
                  <i class="bx bx-chevron-down icon-show"></i
                  ><i class="bx bx-chevron-up icon-close"></i
                ></a>
                <!-- <div id="accordion-list-1" class="collapse show" data-bs-parent=".accordion-list">
                    <p>
                      We provides permanent placement solutions which enable companies to get direct access to top-notch, fully-screened and highly-qualified results in every industry and at every level. It’s less time consuming, more cost effective.
                    </p>
                  </div> -->
              </li>
              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#accordion-list-2"
                  class="collapsed"
                  ><span>02</span>WE’RE PROFESSIONALS, EXPERIENCED
                  <i class="bx bx-chevron-down icon-show"></i
                  ><i class="bx bx-chevron-up icon-close"></i
                ></a>
                <!-- <div id="accordion-list-2" class="collapse" data-bs-parent=".accordion-list">
                    <p>
                      We provides permanent solutions to your company’s business objectives that fit your business needs.
                    </p>
                  </div> -->
              </li>
              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#accordion-list-3"
                  class="collapsed"
                  ><span>03</span>WE’RE RELIABLE, TRUSTED
                  <i class="bx bx-chevron-down icon-show"></i
                  ><i class="bx bx-chevron-up icon-close"></i
                ></a>
                <!-- <div id="accordion-list-3" class="collapse" data-bs-parent=".accordion-list">
                    <p>
                    </p>
                  </div> -->
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style>
.video-box {
  background-image: url("../../assets/images/WhyChooseUs.jpg");
}
.why-us {
  background: #f9f9f9;
  padding: 0;
}
@media (max-width: 992px) {
  .datasection {
    padding: 30px 40px !important;
  }
}
.why-us .content {
  padding: 60px 100px 0 100px;
}

.why-us .content h3 {
  font-weight: 400;
  font-size: 34px;
}
.datasection {
  padding: 0px 30px;
}
.why-us .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.why-us .content p {
  font-size: 15px;
  color: #848484;
}

.why-us .video-box {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 400px;
  position: relative;
}

.why-us .accordion-list {
  padding: 0 100px 60px 100px;
}

.why-us .accordion-list ul {
  padding: 0;
  list-style: none;
}

.why-us .accordion-list li + li {
  margin-top: 15px;
}

.why-us .accordion-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

.why-us .accordion-list a {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 30px;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}

.why-us .accordion-list span {
  color: #0880e8;
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}

.why-us .accordion-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.why-us .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.why-us .accordion-list .icon-show {
  display: none;
}

.why-us .accordion-list a.collapsed {
  color: #343a40;
}

.why-us .accordion-list a.collapsed:hover {
  color: #0880e8;
}

.why-us .accordion-list a.collapsed .icon-show {
  display: inline-block;
}

.why-us .accordion-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1024px) {
  .why-us .content,
  .why-us .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .why-us .content {
    padding-top: 30px;
  }
  .why-us .accordion-list {
    padding-bottom: 30px;
  }
}
</style>
