<template>
  <section class="feature bg-2">
    <div class="container">
      <div class="row justify-content-end">
        <div class="col-md-6">
          <h2 class="section-subtitle">What we offer</h2>
          <p>
            With our dedicated team who works with passion, we
            <i>Discover -> Plan -> Execute -> Deliver</i> effective results. We
            innovate with brainstorming ideas to excel in the projects we
            undertake and to ensure client satisfaction.
          </p>
          <p>
            We offer cost-effective, secure, and scalable models to service our
            clients across various verticals such as fin-tech, education, and
            entertainment to quote a few...
          </p>
          <p>
            Our innovations have allowed us to provide an SLA anywhere around
            the clock - 24*7 and the ability to scale over the needs.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style>
.bg-2 {
  background: url(../../assets/images/FeatueBG.jpg);
  background-size: cover;
  background-attachment: fixed;
}
.feature {
  display: block;
  position: relative;
  padding: 100px 80px;
}
h2.section-subtitle {
  margin-bottom: 40px;
}
@media (max-width: 751px) and (min-width: 0px) {
  section.feature.bg-2 {
    background-image: none;
    background-color: #f2f2f2;
  }
}
@media (max-width: 998px) and (min-width: 752px) {
  .bg-2 {
    background-attachment: scroll !important;
  }
}
@media (max-width: 992px) {
  .feature {
    padding: 30px 40px;
  }
}
</style>
