<template>
  <section id="services" class="services">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Services We Provide</h2>
      </div>
      <div class="row">
        <div
          class="col-lg-6 col-md-6 d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <a href="../into-cloud">
            <div class="icon-box">
              <div class="">
                <ICON_IntoCloud />
              </div>
              <h3><a href="../into-cloud">InTo Cloud</a></h3>
              <p class="servicecontent">
                We will help you build your brand in the digital space that you
                & your customers will be able to access across all digital
                mediums instantly 24*7.
              </p>
              <p class="text-center learnmore pt-3">
                <a href="../into-cloud">Learn More</a>
              </p>
            </div></a
          >
        </div>
        <div
          class="col-lg-6 col-md-6 d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <a href="../cyber-security">
            <div class="icon-box">
              <div class="">
                <ICON_Cyber :width="30" />
              </div>
              <h3><a href="../cyber-security">Cyber Security</a></h3>
              <p class="servicecontent">
                IT and Cyber Security go hand-in-hand. We maximize your success
                by ensuring your business is secure and safe by identifying the
                vulnerabilities and aid in remediating them.
              </p>
              <p class="text-center learnmore pt-3">
                <a href="../cyber-security">Learn More</a>
              </p>
            </div></a
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ICON_IntoCloud from "@/assets/icons/IntoCloud.vue";
import ICON_Cyber from "@/assets/icons/Cyber.vue";

export default {
  components: {
    ICON_IntoCloud,
    ICON_Cyber,
  },
};
</script>

<style scoped>
@media (max-width: 991px) {
  section {
    padding: 30px 40px !important;
  }
}
section {
  padding: 150px 0;
  overflow: hidden;
}
.section-title {
  text-align: center;
  padding-bottom: 30px;
}
.services .icon-box {
  text-align: center;
  padding: 20px 50px;
  transition: all ease-in-out 0.3s;
  background: #fff;
}
.services .icon-box h3 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 23px;
  margin-top: 30px;
}
.learnmore a {
  font-weight: 600;
  font-size: 15px;
  color: #0880e8;
}
.services .icon-box h3 a {
  color: #222222;
  transition: ease-in-out 0.3s;
  text-decoration: none;
}
.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.services .icon-box:hover {
  background: #f7f9fd;
  border-radius: 10px;
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
  cursor: pointer;
  border: 1px solid #4a90e2;
}
</style>
