<template>
  <ContactBanner />
  <Header />
  <Herosection />
  <About />
  <Aboutus />
  <Service />
  <Whywechoose />
  <Contact />
  <Footer />
</template>

<script>
// @ is an alias to /src
import ContactBanner from "@/components/ContactBanner.vue";
import Header from "@/components/Header.vue";
import Herosection from "@/components/landing/HeroSection.vue";
import Service from "@/components/landing/Service.vue";
import Aboutus from "@/components/landing/AboutUs.vue";
import Contact from "@/components/landing/Contact.vue";
import Footer from "@/components/Footer.vue";
import Whywechoose from "@/components/landing/WhyWeChoose.vue";
import About from "@/components/landing/About.vue";

export default {
  components: {
    Header,
    ContactBanner,
    Whywechoose,
    Herosection,
    Service,
    Aboutus,
    Contact,
    Footer,
    About,
  },
};
</script>
